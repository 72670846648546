import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function Services() {
    return (
        <>
            <Sidebar />
            <Headers />

            <div className="main-content">
                <div className="service-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-two">
                                        <i className="flaticon-life-insurance" />
                                    </div>
                                    <div className="text">
                                        <span>$150,000</span>
                                        <p>Total Invested Amount</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-one">
                                        <i className="flaticon-bag" />
                                    </div>
                                    <div className="text">
                                        <span>1,250</span>
                                        <p>Number of Investments</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-four">
                                        <i className="flaticon-shield" />
                                    </div>
                                    <div className="text">
                                        <span>+5.80%</span>
                                        <p>Rate of Return</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-list">
                                    <div className="heading">
                                        <h2>Weekly Activity</h2>
                                    </div>
                                    <div className="service-table">
                                        <div className="table-responsive">
                                            <table>
                                                <tbody><tr>
                                                    <td>
                                                        <div className="icon bg-three">
                                                            <i className="flaticon-loan" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>Business loans</p>
                                                        <span>It is a long established</span>
                                                    </td>
                                                    <td>
                                                        <p>Lorem Ipsum</p>
                                                        <span>Many publishing</span>
                                                    </td>
                                                    <td>
                                                        <p>Lorem Ipsum</p>
                                                        <span>Many publishing</span>
                                                    </td>
                                                    <td>
                                                        <p>Lorem Ipsum</p>
                                                        <span>Many publishing</span>
                                                    </td>
                                                    <td>
                                                        <a href="#">View Details</a>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-one">
                                                                <i className="flaticon-portfolio-black-symbol" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>Checking accounts</p>
                                                            <span>It is a long established</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-three">
                                                                <i className="flaticon-graph" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>Savings accounts</p>
                                                            <span>It is a long established</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-two">
                                                                <i className="flaticon-user" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>Debit and credit cards</p>
                                                            <span>It is a long established</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-four">
                                                                <i className="flaticon-shield" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>Life Insurance</p>
                                                            <span>It is a long established</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-three">
                                                                <i className="flaticon-loan" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>Business loans</p>
                                                            <span>It is a long established</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <p>Lorem Ipsum</p>
                                                            <span>Many publishing</span>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
