import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function CreditCards() {
    return (
        <>
            <Sidebar />
            <Headers />

            {/* main-content start */}
            <div className="main-content">
                <div className="cradit-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cards">
                                    <div className="heading">
                                        <h2>My Cards</h2>
                                    </div>
                                    <div className="card-slider-two row">
                                        <div className="col-lg-4">
                                            <div className="transection-card">
                                                <div className="third-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="/images/chip-img-1.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="/images/master-img.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="single-card">
                                                <div className="first-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="/images/chip-img-1.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="/images/master-img.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="single-card">
                                                <div className="second-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="/images/chip-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="/images/master-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card-list">
                                    <div className="heading">
                                        <h2>Card Expense Statistics</h2>
                                    </div>
                                    <div className="card-table">
                                        <div className="table-responsive">
                                            <table>
                                                <tbody><tr>
                                                    <td>
                                                        <div className="icon bg-two">
                                                            <i className="flaticon-credit-card" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5>Card Type</h5>
                                                        <p>Secondary</p>
                                                    </td>
                                                    <td>
                                                        <h5>Bank</h5>
                                                        <p>DBL Bank</p>
                                                    </td>
                                                    <td>
                                                        <h5>Card Number</h5>
                                                        <p>**** **** 5600</p>
                                                    </td>
                                                    <td>
                                                        <h5>Namain Card</h5>
                                                        <p>William</p>
                                                    </td>
                                                    <td>
                                                        <a href="#">View Details</a>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-three">
                                                                <i className="flaticon-credit-card" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h5>Card Type</h5>
                                                            <p>Secondary</p>
                                                        </td>
                                                        <td>
                                                            <h5>Bank</h5>
                                                            <p>BRC Bank</p>
                                                        </td>
                                                        <td>
                                                            <h5>Card Number</h5>
                                                            <p>**** **** 4300</p>
                                                        </td>
                                                        <td>
                                                            <h5>Namain Card</h5>
                                                            <p>Michel</p>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="icon bg-one">
                                                                <i className="flaticon-credit-card" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h5>Card Type</h5>
                                                            <p>Secondary</p>
                                                        </td>
                                                        <td>
                                                            <h5>Bank</h5>
                                                            <p>ABM Bank</p>
                                                        </td>
                                                        <td>
                                                            <h5>Card Number</h5>
                                                            <p>**** **** 7560</p>
                                                        </td>
                                                        <td>
                                                            <h5>Namain Card</h5>
                                                            <p>Edward</p>
                                                        </td>
                                                        <td>
                                                            <a href="#">View Details</a>
                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="new-card">
                                    <div className="heading">
                                        <h2>Add New Card</h2>
                                    </div>
                                    <div className="add-card">
                                        <div className="details">
                                            <p>Credit Card generally means a plastic card issued by Scheduled Commercial Banks assigned to a Cardholder, with a credit limit, that can be used to purchase goods and services on credit or obtain cash advances.</p>
                                        </div>
                                        <form action="#">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="single-form">
                                                        <p>Card Type</p>
                                                        <input type="text" placeholder="Classic" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="single-form">
                                                        <p>Name On Card</p>
                                                        <input type="text" placeholder="My Cards" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="single-form">
                                                        <p>Card Number</p>
                                                        <input type="text" placeholder="**** **** **** ****" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="single-form">
                                                        <p>Expiration Date</p>
                                                        <input type="date" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="add-btn">
                                                        <button type="submit">Add Card</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-setting">
                                    <div className="heading">
                                        <h2>Card Setting</h2>
                                    </div>
                                    <div className="setting-list">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <div className="icon bg-one">
                                                        <i className="flaticon-block-credit-card" />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Block Card</h5>
                                                        <p>Instantly block your card</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="icon bg-two">
                                                        <i className="flaticon-padlock" />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Change Pin Code</h5>
                                                        <p>Choose another pin code</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="icon bg-three">
                                                        <i className="flaticon-group-249" />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Add to Google Pay</h5>
                                                        <p>Withdraw without any card</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="icon bg-four">
                                                        <i className="flaticon-apple" />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Add to Apple Pay</h5>
                                                        <p>Withdraw without any card</p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <div className="icon bg-four">
                                                        <i className="flaticon-apple" />
                                                    </div>
                                                    <div className="text">
                                                        <h5>Add to Apple Store</h5>
                                                        <p>Withdraw without any card</p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* main-content end */}

        </>
    )
}
