
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Dashboard from './page/Dashboard';
import Transactions from './page/Transactions';
import Accounts from './page/Accounts';
import Investments from './page/Investments';
import CreditCards from './page/CreditCards';
import Loans from './page/Loans';
import Services from './page/Services';
import Setting from './page/Setting';
import Login from './auth/Login';
import Register from './auth/Register';
import PrivateLayout from './layouts/PrivateLayout';
import PublicLayout from './layouts/PublicLayout';

function App() {

  const router = createBrowserRouter([
    {
      element: <PrivateLayout />,
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: '/transaction',
          element: <Transactions />
        },
        {
          path: '/account',
          element: <Accounts />
        },
        {
          path: '/investment',
          element: <Investments />
        },
        {
          path: '/credit-cards',
          element: <CreditCards />
        },
        {
          path: '/loan',
          element: <Loans />
        },
        {
          path: '/service',
          element: <Services />
        },
        {
          path: '/setting',
          element: <Setting />
        }
      ]
    },
    {
      element: <PublicLayout />,
      children: [
        {
          path: '/login',
          element: <Login />
        },
        {
          path: '/register',
          element: <Register />
        },
      ]
    }
  ])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
