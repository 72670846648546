import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROOT_URL } from '../utlis/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function Register() {

  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [validations, setValidations] = useState({
    username: false,
    email: false,
    password: false,
    confirmPassword: false
  })
  const [loading, setLoading] = useState()
  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputs(prev => ({ ...prev, [name]: value }))
    if (validations[name])
      setValidations(prev => ({ ...prev, [name]: false }))
  }

  const checkValidations = () => {
    if (loading) return
    if (!inputs.username) {
      setValidations(prev => ({ ...prev, username: true }))
      return
    }
    if (!/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z0-9-]*[a-zA-Z])?$/.test(inputs.email)) {
      setValidations(prev => ({ ...prev, email: true }))
      return
    }
    if (inputs.password.length < 8) {
      setValidations(prev => ({ ...prev, password: true }))
      return
    }
    if (inputs.password != inputs.confirmPassword) {
      setValidations(prev => ({ ...prev, confirmPassword: true }))
      return
    }
    onRegister()
  }

  const onRegister = async () => {
    try {
      setLoading(true)
      const res = await axios.post(
        `${ROOT_URL}/register`,
        {
          username: inputs.username,
          email: inputs.email,
          password: inputs.password
        }
      )
      MessageBox('success',res.data.msg)
      navigate('/login')
    } catch (err) {
      console.log(err)
      MessageBox('error', err?.response?.data?.msg || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='my_login'>
        <div className="my_card">
          <div className='login_logo text-center'>
            <Link to="/"><img src="images/logo.svg" alt="images" /></Link>
          </div>
          <div className='heading text-center mt-3'>
            <h1>Register</h1>
          </div>
          <div className="single-form mb-3">
            <p>User Name</p>
            <input
              type="text"
              placeholder="Enter username"
              name='username'
              value={inputs.username}
              onChange={handleInputChange}
            />
            {validations.username && <div className='err_msg'>Please enter username</div>}
          </div>
          <div className="single-form mb-3">
            <p>Email</p>
            <input
              type="email"
              placeholder="Enter email"
              name='email'
              value={inputs.email}
              onChange={handleInputChange}
            />
            {validations.email && <div className='err_msg'>{inputs.email ? 'Invalid email' : 'Please enter email'}</div>}
          </div>
          <div className="single-form mb-3">
            <p>Password</p>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              name='password'
              value={inputs.password}
              onChange={handleInputChange}
            />
            {validations.password && <div className='err_msg'>{inputs.password ? 'Password must be 8 characters' : 'Please enter password'}</div>}
          </div>
          <div className="single-form mb-3">
            <p>Confirm Password</p>
            <input
              type="password"
              className="form-control"
              placeholder="Enter confirm password"
              name='confirmPassword'
              value={inputs.confirmPassword}
              onChange={handleInputChange}
            />
            {validations.confirmPassword && <div className='err_msg'>{inputs.confirmPassword ? 'Password does not match' : 'Please confirm password'}</div>}
          </div>
          <div className="save-button text-center mb-3">
            <button onClick={checkValidations}>{loading ? 'Loading...' : 'Register'}</button>
          </div>
          <div className="save-button text-center">
            <p>
              Back to?
              <Link to={'/login'}>&nbsp;Login</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
