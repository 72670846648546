import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function Loans() {
    return (
        <>
            <Sidebar />
            <Headers />

            <div className="main-content">
                <div className="loans-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="single-balance-three">
                                    <div className="icon bg-two">
                                        <i className="flaticon-user" />
                                    </div>
                                    <div className="text">
                                        <p>Personal Loans</p>
                                        <span>$50,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="single-balance-three">
                                    <div className="icon bg-one">
                                        <i className="flaticon-portfolio-black-symbol" />
                                    </div>
                                    <div className="text">
                                        <p>Corporate Loans</p>
                                        <span>$100,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="single-balance-three">
                                    <div className="icon bg-three">
                                        <i className="flaticon-graph" />
                                    </div>
                                    <div className="text">
                                        <p>Business Loans</p>
                                        <span>$500,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="single-balance-three">
                                    <div className="icon bg-four">
                                        <i className="flaticon-service" />
                                    </div>
                                    <div className="text">
                                        <p>Custom Loans</p>
                                        <span>Choose Money</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="loans-overview">
                                    <div className="heading">
                                        <h2>Weekly Activity</h2>
                                    </div>
                                    <div className="overview-table">
                                        <div className="table-responsive">
                                            <table>
                                                <tbody><tr>
                                                    <th>SL No</th>
                                                    <th>Loan Money</th>
                                                    <th>Left to repay</th>
                                                    <th>Duration</th>
                                                    <th>Interest rate</th>
                                                    <th>Installment</th>
                                                    <th>Repay</th>
                                                </tr>
                                                    <tr>
                                                        <td>01.</td>
                                                        <td>$100,000</td>
                                                        <td>$40,500</td>
                                                        <td>8 Months</td>
                                                        <td>12%</td>
                                                        <td>$2,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>02.</td>
                                                        <td>$500,000</td>
                                                        <td>$250,000</td>
                                                        <td>36 Months</td>
                                                        <td>10%</td>
                                                        <td>$8,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>03.</td>
                                                        <td>$900,000</td>
                                                        <td>$40,500</td>
                                                        <td>12 Months</td>
                                                        <td>12%</td>
                                                        <td>$5,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>04.</td>
                                                        <td>$50,000</td>
                                                        <td>$40,500</td>
                                                        <td>25 Months</td>
                                                        <td>5%</td>
                                                        <td>$2,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>05.</td>
                                                        <td>$100,000</td>
                                                        <td>$40,500</td>
                                                        <td>5 Months</td>
                                                        <td>16%</td>
                                                        <td>$10,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>06.</td>
                                                        <td>$80,000</td>
                                                        <td>$25,500</td>
                                                        <td>14 Months</td>
                                                        <td>8%</td>
                                                        <td>$2,000 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>07.</td>
                                                        <td>$12,000</td>
                                                        <td>$5,500</td>
                                                        <td>9 Months</td>
                                                        <td>13%</td>
                                                        <td>$500 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>08.</td>
                                                        <td>$160,000</td>
                                                        <td>$100,800</td>
                                                        <td>3 Months</td>
                                                        <td>12%</td>
                                                        <td>$900 / month</td>
                                                        <td>
                                                            <a href="#">Repay</a>
                                                        </td>
                                                    </tr>
                                                    <tr className="last-row">
                                                        <td>Total</td>
                                                        <td>$125,0000</td>
                                                        <td>$750,000</td>
                                                        <td style={{ color: '#ffffff' }}>.</td>
                                                        <td style={{ color: '#ffffff' }}>.</td>
                                                        <td>$50,000 / month</td>
                                                        <td style={{ color: '#ffffff' }}>.</td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
