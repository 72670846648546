import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function Dashboard() {
    return (
        <>
            <Sidebar />
            <Headers />
            {/* main-content start */}
            <div className="main-content">
                <div className="dashboard-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cards">
                                    <div className="heading">
                                        <h2>My Cards</h2>
                                        <a href="#">See All</a>
                                    </div>
                                    <div className="card-slider row">
                                        <div className="col-lg-6">
                                            <div className="single-card">
                                                <div className="first-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="images/chip-img-1.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="images/master-img.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-card">
                                                <div className="second-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="images/chip-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="images/master-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="recent-transaction">
                                    <div className="heading">
                                        <h2>Recent Transaction</h2>
                                    </div>
                                    <div className="transition-list">
                                        <ul>
                                            <li className="card-transition">
                                                <div className="icon">
                                                    <i className="flaticon-credit-card-1" />
                                                </div>
                                                <div className="name">
                                                    <h5>Deposit from my Card</h5>
                                                    <p>28 January 2021</p>
                                                </div>
                                                <div className="amount">
                                                    <span>-$850</span>
                                                </div>
                                            </li>
                                            <li className="paypal-transition">
                                                <div className="icon">
                                                    <i className="flaticon-paypal" />
                                                </div>
                                                <div className="name">
                                                    <h5>Deposit Paypal</h5>
                                                    <p>25 January 2021</p>
                                                </div>
                                                <div className="amount">
                                                    <span>+$2,500</span>
                                                </div>
                                            </li>
                                            <li className="jemi-transition">
                                                <div className="icon">
                                                    <i className="flaticon-coin" />
                                                </div>
                                                <div className="name">
                                                    <h5>Jemi Wilson</h5>
                                                    <p>21 January 2021</p>
                                                </div>
                                                <div className="amount">
                                                    <span>+$5,400</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-8">
                                <div className="long-chart">
                                    <div className="heading">
                                        <h2>Weekly Activity</h2>
                                    </div>
                                    <div className="main-chart">
                                        <div className="points">
                                            <div className="deposit">
                                                <span>Diposit</span>
                                            </div>
                                            <div className="withdraw">
                                                <span>Withdraw</span>
                                            </div>
                                        </div>
                                        <div id="chartContainer" style={{ height: '370px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="pie-chart">
                                    <div className="heading">
                                        <h2>Expense Statistics</h2>
                                    </div>
                                    <div className="main-chart">
                                        <div id="piechartContainer" style={{ height: '370px', width: '100%' }} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-5">
                                <div className="quick-transfer">
                                    <div className="heading">
                                        <h2>Quick Transfer</h2>
                                    </div>
                                    <div className="transfer">
                                        <div className="transfer-active">
                                            <div className="single-transfer ceo">
                                                <div className="transfer-img">
                                                    <img src="images/transfer-img-1.png" alt="images" />
                                                </div>
                                                <div className="name">
                                                    <p>Livia Bator</p>
                                                    <span>CEO</span>
                                                </div>
                                            </div>
                                            <div className="single-transfer">
                                                <div className="transfer-img">
                                                    <img src="images/transfer-img-2.png" alt="images" />
                                                </div>
                                                <div className="name">
                                                    <p>Randy Press</p>
                                                    <span>Director</span>
                                                </div>
                                            </div>
                                            <div className="single-transfer">
                                                <div className="transfer-img">
                                                    <img src="images/transfer-img-3.png" alt="images" />
                                                </div>
                                                <div className="name">
                                                    <p>Workman</p>
                                                    <span>Designer</span>
                                                </div>
                                            </div>
                                            <div className="single-transfer">
                                                <div className="transfer-img">
                                                    <img src="images/transfer-img-3.png" alt="images" />
                                                </div>
                                                <div className="name">
                                                    <p>Workman</p>
                                                    <span>Designer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="transfer-amount">
                                            <p>Write Amount</p>
                                            <form action="#">
                                                <input type="text" placeholder="525.50" />
                                                <button type="submit">Send <i className="flaticon-send" /></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="balance-history">
                                    <div className="heading">
                                        <h2>Balance History</h2>
                                    </div>
                                    <div className="balance-chart">
                                        <div className="main-chart">
                                            <div id="areachartContainer" style={{ height: '235px', width: '100%' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* main-content end */}
        </>
    )
}
