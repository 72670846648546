import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
    return (
        <>
            {/* left-sidebar start */}
            <aside>
                <div className="left-sidebar">
                    <div className="logo-area">
                        <div className="main-logo">
                            <Link to="/"><img src="images/logo.svg" alt="images" /></Link>
                        </div>
                    </div>
                    <div className="sidebar-menu">
                        <ul>
                            <li className="active">
                                <Link to="/">
                                    <i className="flaticon-home" />
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/transaction">
                                    <i className="flaticon-money-transfer" />
                                    <span>Transactions</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/account">
                                    <i className="flaticon-user" />
                                    <span>Accounts</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/investment">
                                    <i className="flaticon-economic-investment" />
                                    <span>Investments</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/credit-cards">
                                    <i className="flaticon-credit-card" />
                                    <span>Credit Cards</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/loan">
                                    <i className="flaticon-loan" />
                                    <span>Loans</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/service">
                                    <i className="flaticon-service" />
                                    <span>Services</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/">
                                    <i className="flaticon-econometrics" />
                                    <span>My Privileges</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/setting">
                                    <i className="flaticon-settings" />
                                    <span>Setting</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
            {/* left-sidebar end */}
        </>
    )
}
