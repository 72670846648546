import React from 'react'
import { Link } from 'react-router-dom'

export default function Headers() {
    return (
        <>
            {/* top-bar start */}
            <header>
                <div className="top-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-3">
                                <div className="top-heading">
                                    <h2>Overview</h2>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="search-bar">
                                    <form action="#">
                                        <button><i className="flaticon-magnifying-glass" /></button>
                                        <input type="text" placeholder="Search for something" />
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-5">
                                <div className="settings">
                                    <a href="#">
                                        <i className="flaticon-settings-1" />
                                    </a>
                                </div>
                                <div className="notofication">
                                    <a href="#">
                                        <i className="flaticon-notification" />
                                    </a>
                                </div>
                                <div className="profile">
                                    <a href="#">
                                        <img src="images/profile.png" alt="images" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="toogle-bar">
                        <span id="toggle"><i className="fas fa-bars" /></span>
                        <Link className='text-danger' to={'/login'} onClick={() => localStorage.clear()}><b>Logout</b></Link>
                    </div>
                </div>
            </header>
            {/* top-bar end */}
        </>
    )
}
