import React from 'react'
import Headers from '../components/Headers'
import Sidebar from '../components/Sidebar'

export default function Transactions() {
    return (
        <>
            <Sidebar />
            <Headers />

            <div className="main-content">
                <div className="transaction-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cards">
                                    <div className="heading">
                                        <h2>My Cards</h2>
                                        <a href="#">+ Add Card</a>
                                    </div>
                                    <div className="card-slider row">
                                        <div className="col-lg-6">
                                            <div className="single-card">
                                                <div className="first-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="images/chip-img-1.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="images/master-img.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-card">
                                                <div className="second-card">
                                                    <div className="chip-area">
                                                        <div className="balance">
                                                            <p>Balance</p>
                                                            <span>$5,756</span>
                                                        </div>
                                                        <div className="chip-img">
                                                            <img src="images/chip-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                    <div className="holder">
                                                        <div className="single-holder">
                                                            <p>CARD HOLDER</p>
                                                            <span>Eddy Cusuma</span>
                                                        </div>
                                                        <div className="single-holder">
                                                            <p>VALID THRU</p>
                                                            <span>12/22</span>
                                                        </div>
                                                    </div>
                                                    <div className="card-number">
                                                        <p>3778 **** **** 1234</p>
                                                        <div className="master-img">
                                                            <img src="images/master-img-2.png" alt="images" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="expense">
                                    <div className="heading">
                                        <h2>My Expense</h2>
                                    </div>
                                    <div className="expense-chart">
                                        <div id="expartchartContainer" style={{ height: '200px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="heading">
                                    <h2>Recent Transactions</h2>
                                </div>
                                <div className="transection-table">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">All Transactions</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Income</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Expense</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="table-responsive">
                                                <table>
                                                    <tbody><tr>
                                                        <th>
                                                            <p>Description</p>
                                                        </th>
                                                        <th>
                                                            <p>Transaction ID</p>
                                                        </th>
                                                        <th>
                                                            <p>Type</p>
                                                        </th>
                                                        <th>
                                                            <p>Card</p>
                                                        </th>
                                                        <th>
                                                            <p>Date</p>
                                                        </th>
                                                        <th>
                                                            <p>Amount</p>
                                                        </th>
                                                        <th>
                                                            <p>Receipt</p>
                                                        </th>
                                                    </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <div className="table-responsive">
                                                <table>
                                                    <tbody><tr>
                                                        <th>
                                                            <p>Description</p>
                                                        </th>
                                                        <th>
                                                            <p>Transaction ID</p>
                                                        </th>
                                                        <th>
                                                            <p>Type</p>
                                                        </th>
                                                        <th>
                                                            <p>Card</p>
                                                        </th>
                                                        <th>
                                                            <p>Date</p>
                                                        </th>
                                                        <th>
                                                            <p>Amount</p>
                                                        </th>
                                                        <th>
                                                            <p>Receipt</p>
                                                        </th>
                                                    </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="table-responsive">
                                                <table>
                                                    <tbody><tr>
                                                        <th>
                                                            <p>Description</p>
                                                        </th>
                                                        <th>
                                                            <p>Transaction ID</p>
                                                        </th>
                                                        <th>
                                                            <p>Type</p>
                                                        </th>
                                                        <th>
                                                            <p>Card</p>
                                                        </th>
                                                        <th>
                                                            <p>Date</p>
                                                        </th>
                                                        <th>
                                                            <p>Amount</p>
                                                        </th>
                                                        <th>
                                                            <p>Receipt</p>
                                                        </th>
                                                    </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-up-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <i className="flaticon-down-arrow" />
                                                                <p>Spotify Subscription</p>
                                                            </td>
                                                            <td>
                                                                <p>#12548796</p>
                                                            </td>
                                                            <td>
                                                                <p>Shopping</p>
                                                            </td>
                                                            <td>
                                                                <p>1234 ****</p>
                                                            </td>
                                                            <td>
                                                                <p>28 Jan, 12.30 AM</p>
                                                            </td>
                                                            <td>
                                                                <span style={{ color: '#16DBAA' }}>-$2,500</span>
                                                            </td>
                                                            <td>
                                                                <a href="#">Download</a>
                                                            </td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>
                                    </div>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end">
                                            <li className="page-item">
                                                <a className="page-link" href="#" tabIndex={-1} aria-disabled="true"><i className="fas fa-chevron-left" />Previous</a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#">Next<i className="fas fa-chevron-right" /></a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
