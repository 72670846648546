import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function Setting() {
    return (
        <>
            <Sidebar />
            <Headers />

            <div className="main-content">
                <div className="setting-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="settings-tab">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Edit Profile</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Preferences</a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Security</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <div className="edit-profile">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-4">
                                                        <div className="profile-img">
                                                            <img src="images/profile-2.png" alt="images" />
                                                            <div className="edit-icon">
                                                                <a href="#"><img src="images/pencil.png" alt="images" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="profile-form">
                                                            <form action="#">
                                                                <div className="single-form">
                                                                    <p>Your Name</p>
                                                                    <input type="text" placeholder="Charlene Reed" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Email</p>
                                                                    <input type="email" placeholder="charlenereed@gmail.com " />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Date of Birth</p>
                                                                    <input type="date" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Permanent Address</p>
                                                                    <input type="text" placeholder="San Jose, California, USA" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Postal Code</p>
                                                                    <input type="text" placeholder={45962} />
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="profile-form">
                                                            <form action="#">
                                                                <div className="single-form">
                                                                    <p>User Name</p>
                                                                    <input type="text" placeholder="Charlene Reed" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Password</p>
                                                                    <input type="password" placeholder="**********" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Present Address</p>
                                                                    <input type="text" placeholder="San Jose, California, USA" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>City</p>
                                                                    <input type="text" placeholder="San Jose" />
                                                                </div>
                                                                <div className="single-form">
                                                                    <p>Country</p>
                                                                    <input type="text" placeholder="USA" />
                                                                </div>
                                                                <div className="save-button">
                                                                    <button type="submit">Save</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <div className="preference-edit">
                                                <div className="preference-form">
                                                    <form action="#">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="single-form">
                                                                    <p>Currency</p>
                                                                    <input type="text" placeholder="USD" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="single-form">
                                                                    <p>Time Zone</p>
                                                                    <input type="text" placeholder="(GMT-12:00) International Date Line West" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="notification">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="notification-heading">
                                                                <h3>Notification</h3>
                                                            </div>
                                                            <div className="single-notification">
                                                                <label className="switch">
                                                                    <input type="checkbox" defaultChecked />
                                                                    <span className="slider round" />
                                                                </label>
                                                                <p>I send or receive digita currency</p>
                                                            </div>
                                                            <div className="single-notification">
                                                                <label className="switch">
                                                                    <input type="checkbox" />
                                                                    <span className="slider round" />
                                                                </label>
                                                                <p>I send or receive digita currency</p>
                                                            </div>
                                                            <div className="single-notification">
                                                                <label className="switch">
                                                                    <input type="checkbox" defaultChecked />
                                                                    <span className="slider round" />
                                                                </label>
                                                                <p>I send or receive digita currency</p>
                                                            </div>
                                                            <div className="save-button">
                                                                <button type="submit">Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                            <div className="security-setting">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="notification-heading">
                                                            <h3>Two-factor Authentication</h3>
                                                        </div>
                                                        <div className="single-notification">
                                                            <label className="switch">
                                                                <input type="checkbox" defaultChecked />
                                                                <span className="slider round" />
                                                            </label>
                                                            <p>I send or receive digita currency</p>
                                                        </div>
                                                        <div className="notification-heading">
                                                            <h3>Change Password</h3>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="single-form">
                                                                    <p>Current Password</p>
                                                                    <input type="password" placeholder="**********" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6" />
                                                            <div className="col-lg-6">
                                                                <div className="single-form">
                                                                    <p>New Password</p>
                                                                    <input type="password" placeholder="**********" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="save-button">
                                                            <button type="submit">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
