import React from 'react'
import Sidebar from '../components/Sidebar'
import Headers from '../components/Headers'

export default function Investments() {
    return (
        <>
            <Sidebar />
            <Headers />


            <div className="main-content">
                <div className="investment-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-four">
                                        <i className="flaticon-money-bag-of-dollars" />
                                    </div>
                                    <div className="text">
                                        <p>Total Invested Amount</p>
                                        <span>$150,000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-three">
                                        <i className="flaticon-pie-chart" />
                                    </div>
                                    <div className="text">
                                        <p>Number of Investments</p>
                                        <span>1,250</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="single-balance-two">
                                    <div className="icon bg-two">
                                        <i className="flaticon-repeat" />
                                    </div>
                                    <div className="text">
                                        <p>Rate of Return</p>
                                        <span>+5.80%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="my-invest">
                                    <div className="heading">
                                        <h2>My Investment</h2>
                                    </div>
                                    <div className="invest-list">
                                        <div className="table-responsive">
                                            <table>
                                                <tbody><tr className="single-invest">
                                                    <td>
                                                        <div className="icon bg-three">
                                                            <i className="flaticon-apple-1" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="name">
                                                            <h5>Apple Store</h5>
                                                            <p>E-commerce, Marketplace</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="price">
                                                            <h5>$54,000</h5>
                                                            <p>Envestment Value</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="discount">
                                                            <span>+16%</span>
                                                            <p>Return Value</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    <tr className="single-invest">
                                                        <td>
                                                            <div className="icon bg-two">
                                                                <i className="flaticon-group-249" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="name">
                                                                <h5>Samsung Mobile</h5>
                                                                <p>E-commerce, Marketplace</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="price">
                                                                <h5>$25,300</h5>
                                                                <p>Envestment Value</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="discount">
                                                                <span style={{ color: '#FE5C73' }}>-4%</span>
                                                                <p>Return Value</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="single-invest">
                                                        <td>
                                                            <div className="icon bg-one">
                                                                <i className="flaticon-group" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="name">
                                                                <h5>Tesla Motors</h5>
                                                                <p>Electric Vehicles</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="price">
                                                                <h5>$8,200</h5>
                                                                <p>Envestment Value</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="discount">
                                                                <span>+25%</span>
                                                                <p>Return Value</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="trading-shock">
                                    <div className="heading">
                                        <h2>Trending Stock</h2>
                                    </div>
                                    <div className="trading-table">
                                        <div className="table-responsive">
                                            <table>
                                                <tbody><tr>
                                                    <th>SL No</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Return</th>
                                                </tr>
                                                    <tr>
                                                        <td>01.</td>
                                                        <td>Trivago</td>
                                                        <td>$520</td>
                                                        <td><span>+5%</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>02.</td>
                                                        <td>Canon</td>
                                                        <td>$480</td>
                                                        <td><span>+10%</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>03.</td>
                                                        <td>Uber Food</td>
                                                        <td>$350</td>
                                                        <td><span style={{ color: '#FE5C73' }}>-3%</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>04.</td>
                                                        <td>Nokia</td>
                                                        <td>$940</td>
                                                        <td><span>+2%</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td>05.</td>
                                                        <td>Tiktok</td>
                                                        <td>$670</td>
                                                        <td><span style={{ color: '#FE5C73' }}>-12%</span></td>
                                                    </tr>
                                                </tbody></table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
